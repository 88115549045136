import axios from '../helpers/axios';
import { handleErrorToaster } from '../utils/function';

class ClientService {
    async sendToClient(body, isBulkSend = false) {
        try {
            const url = isBulkSend ? 'client/sendToClient/bulk' : 'client/sendToClient'
            const response = await axios.post(url, body);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            throw error;
        }
    }
}

export default new ClientService();
