import axios from '../helpers/axios';
import { handleErrorToaster } from '../utils/function';

class StatusService {
    async getAllStatus() {
        try {
            const response = await axios.get(`/status`);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            throw Error(error);
        }
    }

    async getAllStatusWithFilter({
        search = "",
        page = 1,
        rowsPerPage = 10
    }) {
        try {
            const response = await axios.get(`/status?search=${search}&page=${page}&rowsPerPage=${rowsPerPage}`);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            throw Error(error);
        }
    }

    async post(body) {
        try {
            const response = await axios.post(`/status`, body);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            throw Error(error);
        }
    }

    async update(id, body) {
        try {
            const response = await axios.put(`/status/${id}`, body);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            throw Error(error);
        }
    }

    async delete(id) {
        try {
            const response = await axios.delete(`/status/${id}`);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            throw Error(error);
        }
    }
}

export default new StatusService();
