import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { redirectToRoleBasedRoute } from "../../utils/auth";
import { toast } from "react-toastify";
import apiService from '../../apiService';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false); 
  const navigate = useNavigate();
  const userIdRef = useRef(null);

  useEffect(() => {
    if (userIdRef.current) {
      userIdRef.current.focus();
    }
  }, []);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Please enter your email address"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .matches(/[A-Z]/, "Password must contain an uppercase letter")
      .matches(/[0-9]/, "Password must contain a number")
      .matches(/[!@#$%^&*]/, "Password must contain a special character")
      .required("Please enter your password"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true);

    const loginData = { email: values.email, password: values.password };
    try {
      const result = await apiService.auth.login(loginData);

      if (result && result.data.accessToken) {
        localStorage.setItem("accessToken", result.data.accessToken);
        redirectToRoleBasedRoute(result.data.accessToken, navigate);
        toast.success(result.message);
      } else {
        userIdRef.current.focus();
      }
    } catch (error) {
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen w-full p-2">
      <div className="max-w-[1000px] set-gap xl:gap-32 lg:gap-20 md:gap-3 mx-auto flex w-full p-5 justify-between items-center">
        <div className="h-[330px] md:max-w-[431px] w-full max-md:hidden p-2">
          <img src="/assets/image/logo/leadbriefcaselogo.png" className="h-full w-full object-contain" alt="" />
        </div>
        <div className="bg-black w-[1px] h-[350px] max-md:hidden"></div>
        <div className="mx-auto md:max-w-[411px] w-full md:pl-12 lg:pl-10 xl:pl-0">
          <div className="flex flex-col justify-start items-center w-full ">
            <h1 className="text-2xl font-bold">Login</h1>

            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="w-full mt-4">
                  <div className="md:max-w-[411px] w-full mx-auto">
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email"
                      className="w-full bg-[#F5F5F5] px-6 py-4 text-lg outline-none"
                      innerRef={userIdRef}
                    />
                    <ErrorMessage name="email" component="div" className="text-xs text-red-500 w-[90%]" />
                  </div>

                  <div className="md:max-w-[411px] w-full mx-auto mt-3 relative">
                    <Field
                      type={passwordVisible ? "text" : "password"} 
                      name="password"
                      placeholder="Password"
                      className="w-full bg-[#F5F5F5] px-6 py-4 text-lg outline-none"
                    />
                    <ErrorMessage name="password" component="div" className="text-xs text-red-500 w-[90%]" />
                    <i
                      className={`fa-solid ${passwordVisible ? " fa-eye" : "fa-eye-slash"} absolute right-4 top-6 transform  cursor-pointer`}
                      onClick={() => setPasswordVisible(!passwordVisible)}  
                    ></i>
                  </div>

                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className="px-14 mx-auto text-lg font-bold mt-14 py-3 rounded-[15px] bg-black text-white"
                      disabled={isSubmitting || loading}
                    >
                      {loading ? <div className="loader"></div> : "Submit"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;