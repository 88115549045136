import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  memo,
  useMemo,
} from "react";
import ApiService from "../../apiService";
import SkeletonLoader from "../skeletonloader/SkeletonLoader";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { roleList } from "../../utils/constant";
import apiService from "../../apiService";
import { isAdmin } from "../../utils/auth";
import Pagination from "../shared/Pagination";
import ClientViewModel from "../model/ClientView";
import "react-dropdown/style.css";
import {
  convertStatusToOption,
  exportToExcel,
  getLeadData,
  getLeadStatusV2,
  isUserAdminOrSubAdmin,
  trimText,
} from "../../utils/function";
import { ReactComponent as SendIcon } from "../../assets/icons/send.svg";
import StatusChangeModel from "../model/StatusChange";
import Select from "react-select";

const LeadsTable = ({ title, userType }) => {
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [leads, setLeads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [leadToDelete, setLeadToDelete] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  const [openSendToClient, setOpenSendToClient] = useState(false);
  const [openStatusChange, setOpenStatusChange] = useState(false);
  const [selectedLead, setSelectedLead] = useState({});
  const [campaignDetails, setCampaignDetails] = useState([]);
  const [apiStatuses, setApiStatuses] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);

  const [selectedStatus, setSelectedStatus] = useState("select");
  const [selectedRole, setSelectedRole] = useState(roleList[0].value);
  const [selectedUser, setSelectedUser] = useState("");
  const [assigneeList, setAssigneeList] = useState([]);
  const [campList, setCampList] = useState([]);
  const [selectedCamp, setSelectedCamp] = useState("select");
  const [downloadModel, setDownloadModel] = useState(false);
  const [userList, setUserList] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);

  const downloadRef = useRef(null);

  const isUserAdminOrSubAdminMemo = useMemo(
    () => isUserAdminOrSubAdmin(userType),
    [userType]
  );
  const isUserAdminMemo = useMemo(isAdmin, []);

  const getQueryParam = useCallback(
    (key) => {
      const params = new URLSearchParams(location.search);
      return params.get(key);
    },
    [location.search]
  );

  const timeframe = getQueryParam("timeframe");

  const navigate = useNavigate();

  const openDownloadModel = () => {
    setDownloadModel(true);
  };
  const closeDownloadModel = () => {
    setDownloadModel(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (downloadRef.current && !downloadRef.current.contains(event.target)) {
        closeDownloadModel();
      }

    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const status = getQueryParam("status");
    if (status) {
      const filteredStatus = apiStatuses.find(
        (st) => st.label === status || st.value === status
      )?.value;
      setSelectedStatus(filteredStatus || "select");
    }
  }, [apiStatuses, getQueryParam, location.search]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const fetchData = useCallback(
    async (page = 1) => {
      setLoading(true);
      try {
        setLeads([]);
        const t = timeframe?.split("-");
        const isCustomFilter = t?.includes("custom");

        const result = await ApiService.leads.getLeads({
          userType,
          selectedStatus: selectedStatus === "select" ? "" : selectedStatus,
          page,
          selectedUser,
          limit: rowsPerPage,
          selectedCamp: selectedCamp === "select" ? "" : selectedCamp,
          selectedRole,
          timeframe: isCustomFilter ? "custom" : timeframe || "",
          startDate: isCustomFilter ? new Date(t[0])?.toISOString() || "" : "",
          endDate: isCustomFilter ? new Date(t[2])?.toISOString() || "" : "",
          search: debouncedSearchQuery,
        });

        if (result?.success) {
          const { data } = result;

          setLeads(data.leads || []);
          setTotalPages(data.totalPages || 1);
          setCurrentPage(data.currentPage || 1);
          setTotalData(data.totalLeads);
        }
      } catch (error) {
        toast.error("Failed to fetch leads.");
      } finally {
        setLoading(false);
      }
    },
    [
      timeframe,
      userType,
      selectedStatus,
      selectedUser,
      rowsPerPage,
      selectedCamp,
      selectedRole,
      debouncedSearchQuery,
    ]
  );

  const fetchAllStatus = useCallback(async () => {
    const data = await apiService.status.getAllStatus();
    if (data?.success) {
      setApiStatuses(convertStatusToOption(data?.data?.statuses));
    }
  }, []);

  useEffect(() => {
    fetchAllStatus();
  }, [fetchAllStatus]);

  const exportAllData = async () => {
    const data = await apiService.leads.getAllLeads();
    exportToExcel(data?.data?.leads);
  };

  // initial fetch functions
  const fetchCampaignDetails = useCallback(async () => {
    try {
      if (!isUserAdminOrSubAdminMemo) {
        const result = await apiService.campaigns.getWithDetails();
        const campaigns = result?.data?.campaigns || [];
        setCampList(
          campaigns?.map((ca) => ({ label: ca?.title, value: ca.campId }))
        );
        setCampaignDetails(campaigns);
      }
    } catch (error) {
      toast.error("Failed to fetch campaign details.");
    }
  }, [isUserAdminOrSubAdminMemo]);

  const fetchAssignees = async () => {
    try {
      const result = await apiService.auth.getUser("staff", "", -1, "verifier");
      const assignees = result?.data?.users || [];
      setAssigneeList(
        assignees.map((ass) => ({ label: ass.name, value: ass._id }))
      );
    } catch (error) {
      toast.error("Failed to fetch assignees.");
    }
  };

  const fetchUsers = useCallback(async () => {
    try {
      if (userType === "subAdmin" || (selectedRole && selectedRole !== "all")) {
        const result = await apiService.auth.getUser(selectedRole);
        const users = result?.data?.users || [];
        setUserList(
          users.map((user) => ({ label: user.name, value: user._id }))
        );
      } else {
        setUserList([]);
        setSelectedUser("");
      }
    } catch (error) {
      toast.error("Failed to fetch user list.");
    }
  }, [selectedRole, userType]);

  const fetchInitialData = useCallback(async () => {
    await Promise.all([fetchCampaignDetails(), fetchAssignees(), fetchUsers()]);
  }, [fetchCampaignDetails, fetchUsers]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  useEffect(() => {
    fetchData(currentPage);
  }, [
    fetchData,
    currentPage,
    selectedStatus,
    debouncedSearchQuery,
    selectedUser,
    rowsPerPage,
  ]);

  const handleEditLead = useCallback(
    (lead, campaignId) => {
      navigate(`/crm/leads/edit/${lead.leadId}/?campaignId=${campaignId}`);
    },
    [navigate]
  );

  const openDeleteConfirmation = (leadId) => {
    setLeadToDelete(leadId);
    setIsDeleteConfirmationOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
    setLeadToDelete(null);
    document.body.style.overflow = "unset";
  };

  const handleDelete = async () => {
    if (!leadToDelete) return;
    setIsDeleting(true);
    try {
      await ApiService.leads.deleteLeads(leadToDelete);
      fetchData();
      toast.success("Lead deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete lead.");
    } finally {
      setIsDeleting(false);
      closeDeleteConfirmation();
    }
  };

  const handleDropdownChange = (option) => {
    setSelectedStatus(option.value);
  };

  const handleUserChange = (option) => {
    setSelectedUser(option.value);
  };

  const handleRoleChange = (option) => {
    setSelectedRole(option.value);
  };

  const onAssigneeChange = useCallback(
    async (assigneeId, leadId) => {
      try {
        const updatedLead = await apiService.leads.updateLead(leadId, {
          verifier: assigneeId,
          status: "under_verification",
        });
        if (updatedLead.success) {
          toast.success(updatedLead.message);
          setLeads((prevLeads) =>
            prevLeads.map((lead) =>
              lead.leadId === leadId ? { ...lead, verifier: assigneeId } : lead
            )
          );
          fetchData();
        }
      } catch (error) {
        toast.error("Failed to update assignee.");
      }
    },
    [fetchData]
  );

  const getAssigneeLabel = useCallback(
    (id) => {
      let label = assigneeList.find((user) => user.value === id)?.label;
      return label || "";
    },
    [assigneeList]
  );

  const getStatusLabel = useCallback(
    (id) => {
      if (id) {
        let label = apiStatuses.find((st) => st.value === id)?.label;
        return label || "";
      }

      return "";
    },
    [apiStatuses]
  );

  const onStatusChange = useCallback(
    async (leadId, status) => {
      try {
        const data = await apiService.leads.updateLead(leadId, { status });
        if (data.success) {
          toast.success(data?.message || "");
          fetchData();
        }
      } catch (error) {
        console.error("Failed to update lead status:", error);
      }
    },
    [fetchData]
  );

  const canDisableSendToClient = (lead) => {
    return (
      lead.status === "reject" ||
      (lead.createdBy.userType === "staff" && lead.status !== "verified")
    );
  };

  const getUserStatus = useCallback((status) => {
    const notAllowedStatus = ["new"]

    if (notAllowedStatus.includes(status?.value)) {
      return apiStatuses.filter((option) =>  option.label === 'New')
    } else {
      return apiStatuses.filter((opt) => !notAllowedStatus.includes(opt.name))
    }
  }, [apiStatuses])


  const getFilterStatus = useCallback(() => {
    return apiStatuses;
  }, [apiStatuses]);

  const getDropdownConfig = useCallback(
    (lead, assigneeList) => {
      if (lead.status === "new" && lead.createdBy.userType === "staff") {
        return {
          options: [{ label: "Assignee", value: "" }, ...assigneeList],
          onChange: (value) => onAssigneeChange(value, lead.leadId),
          value: getAssigneeLabel(lead?.verifier),
          placeholder: "Select an Assignee",
        };
      }

      return {
        options: getUserStatus(lead.status),
        onChange: (value) => onStatusChange(lead.leadId, value),
        value: getStatusLabel(lead?.status?._id),
        placeholder: "Status",
      };
    },
    [
      getAssigneeLabel,
      getStatusLabel,
      getUserStatus,
      onAssigneeChange,
      onStatusChange,
    ]
  );

  const handleCheckboxChange = (leadId, isChecked) => {
    if (isChecked) {
      setSelectedLeads((prevSelectedLeads) => [...prevSelectedLeads, leadId]);
    } else {
      setSelectedLeads((prevSelectedLeads) =>
        prevSelectedLeads.filter((id) => id !== leadId)
      );
    }
  };


  const columns = useMemo(
    () => [
      isUserAdminMemo && {
        key: "select",
        header: "",
        width: "w-[5%]",
        render: (lead) => <input
          type="checkbox"
          checked={selectedLeads.includes(lead.leadId)}
          onChange={(e) => handleCheckboxChange(lead.leadId, e.target.checked)}
        />,
        prevent: true,
      },
      {
        key: "id",
        header: "Id",
        width: "w-[5%]",
        render: (lead) => lead?.leadId?.replaceAll("lead-", " "),
      },
      {
        key: "name",
        header: "Name",
        width: "w-[10%]",
        render: (lead) => getLeadData(lead?.responses, "name") || "-",
      },
      {
        key: "contact",
        header: "Contact",
        width: "w-[10%]",
        render: (lead) => getLeadData(lead?.responses, "number") || "-",
      },
      {
        key: "campaign",
        header: "Campaign",
        width: "w-[10%]",
        render: (lead) => lead?.campaign?.title || "-",
      },
      isUserAdminOrSubAdminMemo && {
        key: "createdBy",
        header: "Created by",
        width: "w-[10%]",
        render: (lead) => lead?.createdBy?.name || "-",
      },
      {
        key: "status",
        header: "Status",
        width: "w-[15%]",
        prevent: true,
        render: (lead) =>
          isUserAdminOrSubAdminMemo
            ? (() => {
              const { options, onChange, value, placeholder } =
                getDropdownConfig(lead, assigneeList);
              return (
                <Select
                  options={options}
                  onChange={(option) => onChange(option.value)}
                  value={options.find(opt => opt.label === value)}
                  placeholder={placeholder}
                  className="font-medium"
                  isSearchable={true}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  menuPortalTarget={document.body}
                />
              );
            })()
            : getLeadStatusV2(lead.status, apiStatuses),
      },
      isUserAdminOrSubAdminMemo && {
        key: "action",
        header: "Action",
        width: "w-[5%]",
        prevent: true,
        render: (lead) => (
          <div className="flex items-center justify-center gap-1 w-full h-full">
            {isUserAdminMemo && (
              <>
                <button
                  className="h-6 w-6"
                  onClick={(event) => {
                    handleEditLead(lead, lead?.campaign?._id);
                  }}
                >
                  <img src="/assets/svg/edit.svg" alt="Edit" />
                </button>
                <button
                  className="h-5 w-5"
                  onClick={(event) => {
                    openDeleteConfirmation(lead.id);
                  }}
                >
                  <img src="/assets/svg/delete.svg" alt="Delete" />
                </button>
              </>
            )}

            <button
              disabled={canDisableSendToClient(lead)}
              onClick={(event) => {
                setSelectedLeads([])
                setSelectedLead(lead);
                setOpenSendToClient(true);
              }}
            >
              <SendIcon
                style={{
                  fill: canDisableSendToClient(lead) ? "gray" : "#7FB8EF",
                }}
              />
            </button>
          </div>
        ),
      },
    ],
    [
      apiStatuses,
      assigneeList,
      getDropdownConfig,
      handleEditLead,
      isUserAdminMemo,
      isUserAdminOrSubAdminMemo,
      selectedLeads
    ]
  ).filter(Boolean)

  return (
    <>
      <div className="flex sm:justify-end justify-between">
        {!isUserAdminMemo && userType !== "subAdmin" && (
          <div className=" w-full flex items-center gap-2 flex-wrap justify-center py-8 border bg-white border-border-dark rounded-[20px]">
            {campaignDetails?.map((camp) => (
              <button
                className="bg-blue-200 text-black font-bold py-2 px-4 rounded-full relative group"
                onClick={() => navigate(`/crm/leads/create/${camp.campId}`)}
              >
                {campaignDetails.length > 2
                  ? trimText(camp?.title, 15)
                  : camp?.title}
                <span className="absolute top-full left-7 mt-2 w-max px-2 py-1 bg-gray-700 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-50">
                  {camp?.title}
                </span>
              </button>
            ))}
          </div>
        )}
      </div>

      <div className="flex flex-col space-y-3">
        <div className="flex justify-between items-center">
          <h1 className="font-bold p-2 leading-8 custom-padding xl:!text-end max-md:text-center  text-2xl col-span-1">
            {title}
          </h1>

          <div className="w-full md:w-4/12">
            <div className="overflow-hidden border border-gray-300 shadow-d rounded-[20px]">
              <div className="relative flex items-end justify-end py-2 bg-white">
                <div className="relative flex items-center w-full">
                  <input
                    type="text"
                    value={searchQuery}
                    className="w-full py-1 pl-12 pr-4 text-sm font-medium text-gray-700 rounded-[20px] focus:outline-none"
                    placeholder="Search by name, email, number..."
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <div className="absolute inset-y-0 flex items-center pointer-events-none left-5">
                    <i className="text-lg text-gray-500 fa-solid fa-magnifying-glass"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="border bg-white border-border-dark mt-4 rounded-[20px] overflow-hidden">
        <div className="flex justify-end gap-2 py-1 px-2 w-full relative flex-wrap">
          <div className="flex max-sm:flex-wrap  justify-center md:justify-end items-center max-md:pb-4 gap-2 col-span-2 sm:col-span-1">
            {!isUserAdminOrSubAdminMemo && (
              <Select
                options={[
                  { label: "Select Campaign", value: "select", isDisabled: true },
                  { label: "All", value: "" },
                  ...campList,
                ]}
                onChange={(option) => setSelectedCamp(option.value)}
                value={campList.find(opt => opt.value === selectedCamp) || { label: "All", value: "" }}
                placeholder="Select Campaign..."
                className="font-medium shrink-0 min-w-dropdown"
                isSearchable={true}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
                menuPortalTarget={document.body}
              />
            )}
            {isUserAdminMemo && (
              <Select
                options={roleList}
                onChange={(option) => handleRoleChange(option)}
                value={roleList.find((opt) => opt.value === selectedRole)}
                placeholder="Search or Select Role..."
                className="font-medium shrink-0"
                isSearchable={true}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
                menuPortalTarget={document.body}
              />
            )}
            {isUserAdminOrSubAdminMemo && (
              <Select
                options={[{ label: "All", value: "" }, ...userList]}
                onChange={(option) => handleUserChange(option)}
                value={userList.find((opt) => opt.value === selectedUser) || { label: "All", value: "" }}
                placeholder="Search or Select User..."
                className="font-medium shrink-0 min-w-dropdown"
                isDisabled={selectedRole === "all"}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
                menuPortalTarget={document.body}
                isSearchable={true}
              />
            )}
            <Select
              options={[
                { label: "All", value: "" },
                ...getFilterStatus(),
              ]}
              onChange={(option) => setSelectedStatus(option.value)}
              value={getFilterStatus().find(opt => opt.value === selectedStatus)}
              placeholder="Select Status"
              className="font-medium shrink-0 min-w-dropdown"
              isSearchable={true}
            />
            <div className="relative">
              <div
                onClick={openDownloadModel}
                className=" bg-[#F9F9F9] px-2 py-[7px] flex items-center  flex-none text-base cursor-pointer font-medium text-start  border border-border-dark rounded-[5px] text-[#333]"
              >
                Export{" "}
                {!downloadModel ? (
                  <span>
                    <i className="fa-solid fa-caret-down text-[#999] ml-3 mt-1"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa-solid fa-caret-up text-[#999] ml-3 mt-1"></i>
                  </span>
                )}
              </div>
              {downloadModel && (
                <div
                  className="absolute top-12 z-50 shadow-lg rounded-lg right-0 p-2 bg-white "
                  ref={downloadRef}
                >
                  <div className="w-[150px] relative rounded-lg px-2">
                    <div className="flex flex-col">
                      <div
                        className="text-black px-1 py-2 text-sm cursor-pointer font-medium text-start"
                        onClick={() => exportAllData()}
                      >
                        All
                      </div>
                      <div
                        className="text-black px-1 py-3 text-sm cursor-pointer font-medium text-start"
                        onClick={() => exportToExcel(leads)}
                      >
                        Filter
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {selectedLeads.length > 0 && <>
              <button
                onClick={() => {
                  setSelectedLead(null);
                  setOpenSendToClient(true);
                }}
                className=" bg-[#F9F9F9] px-2 py-[7px] flex items-center  flex-none text-base cursor-pointer font-medium text-start  border border-border-dark rounded-[5px] text-[#333]">
                Send to client
              </button>
              <button
                onClick={() => {
                  if (selectedLeads.length > 0) {
                    setOpenStatusChange(true)
                  }
                }}
                className=" bg-[#F9F9F9] px-2 py-[7px] flex items-center  flex-none text-base cursor-pointer font-medium text-start  border border-border-dark rounded-[5px] text-[#333]">
                Change status
              </button>
            </>}
          </div>
        </div>

        <div className="w-full border-t border-border-dark min-h-[300px] overflow-x-auto">
          {loading ? (
            <SkeletonLoader />
          ) : leads.length === 0 && !loading ? (
            <div className="flex items-center justify-center min-h-[300px] font-bold text-xl">
              No data found
            </div> // Show "No data found" only if there are no leads
          ) : (
            <table className="w-full border-collapse scrolldown">
              <thead>
                <tr className="bg-[#F6F6F6] h-[41.9px]">
                  {columns.map((col) => (
                    <th
                      key={col.key}
                      className={`text-base font-bold px-4 border-r border-border-dark ${col.width}`}
                    >
                      {col.header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white w-full">
                {leads.map((lead) => (
                  <tr
                    key={lead.leadId}
                    className="border-b border-border-dark"
                    style={{ cursor: isUserAdminMemo ? "pointer" : "default" }}
                    onClick={() => {
                      if (isUserAdminMemo) {
                        navigate(`/crm/leads/view/${lead.leadId}`);
                      }
                    }}
                  >
                    {columns.map((col) => (
                      <td
                        key={col.key}
                        className={`text-sm text-center font-bold px-2 py-3 border-r border-border-dark ${col.textStyle || ""
                          }`}
                        onClick={(e) => {
                          col.prevent && e.stopPropagation();
                        }}
                      >
                        {typeof col.render === 'function' && col?.render(lead)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          totalData={totalData}
          onPageChange={setCurrentPage}
          loading={loading}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(+e.target.value);
            setCurrentPage(1);
          }}
        />

        <ClientViewModel
          isOpen={openSendToClient}
          onClose={() => {
            setOpenSendToClient(false);
          }}
          setSelectedLeads={setSelectedLeads}
          fetchData={fetchData}
          lead={selectedLead}
          selectedLeads={selectedLeads}
        />

        <StatusChangeModel
          apiStatuses={apiStatuses}
          setSelectedLeads={setSelectedLeads}
          isOpen={openStatusChange}
          onClose={() => {
            setOpenStatusChange(false);
          }}
          fetchData={fetchData}
          selectedLeads={selectedLeads}
        />

        {isDeleteConfirmationOpen && (
          <div className="fixed inset-0 z-[1000] bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white rounded-lg p-4">
              <p className="text-lg font-semibold py-10">
                Are you sure you want to delete this lead?
              </p>
              <div className="flex justify-end mt-4 font-semibold">
                <button
                  className={`bg-[#AC1218] text-white py-1 ${isDeleting ? " px-6" : "px-2"
                    } text-sm font-semibold rounded-md`}
                  onClick={handleDelete}
                  disabled={isDeleting}
                >
                  {isDeleting ? <div className="loader"></div> : "Delete"}
                </button>
                <button
                  className="ml-4 border border-border-color py-1 px-2 text-sm rounded-md"
                  onClick={closeDeleteConfirmation}
                  disabled={isDeleting}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(LeadsTable);
