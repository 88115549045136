import { createBrowserRouter, Navigate } from "react-router-dom";
import AuthenticatedLayout from "../layouts/AuthenticatedLayout.jsx";
import UnauthenticatedLayout from "../layouts/UnauthenticatedLayout.jsx";
import DashBoard from "../pages/dashboard/DashBoard.jsx";
import Leads from "../pages/leads/Leads.jsx";
import Clients from "../pages/clients/Clients.jsx";
import Vendors from "../pages/vendors/Vendors.jsx";
import Staff from "../pages/staff/Staff.jsx";
import Login from "../pages/login/Login.jsx";
import PrivateRoute from "./privateRoutes.js";
import Unauthorized from "../components/shared/Unauthorized.jsx";
import NotFound from "../components/shared/NotFound.jsx";
import Question from "../pages/question/Question.jsx";
import LeadsForm from "../components/forms/Lead.jsx";
import Request from "../pages/request/Request.jsx";
import Campaigns from "../pages/campaign/Campaign.jsx";
import LeadView from "../components/views/LeadView.jsx";
import AdminLeadView from "../components/views/AdminLeadView.jsx";
import ApiLeadsForm from "../pages/vendors/ApiLeadsForm.jsx";
import SubAdmin from "../pages/sub-admin";
import ProjectDocument from "../pages/projectDocument/ProjectDocument.jsx";
import ProjectDocument2 from "../pages/projectDocument/ProjectDocumnet2.jsx";
import ProjectDocument3 from "../pages/projectDocument/ProjectDocumnet3.jsx";
import ProjectDocument4 from "../pages/projectDocument/ProjectDocumnet4.jsx";
import ProjectDocument5 from "../pages/projectDocument/ProjectDocument5.jsx";
import LeadHistory from "../pages/lead-history/LeadHistory.jsx";
import ProjectDocument6 from "../pages/projectDocument/ProjectDocument6.jsx";
import ProjectDocument7 from "../pages/projectDocument/ProjectDocument7.jsx";
import ProjectDocument8 from "../pages/projectDocument/ProjectDocument8.jsx";
import ProjectDocument9 from "../pages/projectDocument/ProjectDocument9.jsx";
import StatusModule from "../pages/status/index.jsx";
import ProjectDocument10 from "../pages/projectDocument/ProjectDocument10.jsx";

const router = createBrowserRouter([
  {
    path: "vendor-1-api-doc",
    element: <ProjectDocument />,
  },
  {
    path: "vendor-2-api-doc",
    element: <ProjectDocument2 />,
  },
  {
    path: "vendor-3-api-doc",
    element: <ProjectDocument3 />,
  },
  {
    path: "vendor-4-api-doc",
    element: <ProjectDocument4 />,
  },
  {
    path: "vendor-5-api-doc",
    element: <ProjectDocument5 />,
  },
  {
    path: "vendor-6-api-doc",
    element: <ProjectDocument6 />,
  },
  {
    path: "vendor-7-api-doc",
    element: <ProjectDocument7 />,
  },
  {
    path: "vendor-8-api-doc",
    element: <ProjectDocument8 />,
  },
  {
    path: "vendor-9-api-doc",
    element: <ProjectDocument9 />,
  },
  {
    path: "vendor-10-api-doc",
    element: <ProjectDocument10 />,
  },
  {
    path: "/",
    element: <UnauthenticatedLayout />,
    children: [
      {
        path: "",
        element: <Login />,
      }
    ],
  },
  {
    path: "crm",
    element: (
      <PrivateRoute
        element={<AuthenticatedLayout />}
        allowedRoles={["staff", "vendor", "admin", "client", "subAdmin"]}
      />
    ),
    children: [
      { path: "", element: <Navigate to="dashboard" replace /> },
      {
        path: "dashboard",
        element: (
          <PrivateRoute element={<DashBoard />} allowedRoles={["admin", "vendor", "staff", 'client', 'subAdmin']} />
        ),
      },
      {
        path: "leads",
        element: (
          <PrivateRoute element={<Leads />} allowedRoles={["admin", "staff", "vendor", 'subAdmin']} />
        ),
      },
      {
        path: "leads/view/:id",
        element: (
          <PrivateRoute element={<AdminLeadView />} allowedRoles={["admin"]} />
        ),
      },
      {
        path: "leads/create/:campId",
        element: (
          <PrivateRoute element={<LeadsForm />} allowedRoles={["staff", "vendor"]} />
        ),
      },
      {
        path: "leads/edit/:id",
        element: <PrivateRoute element={<LeadsForm isEditMode={true} />} allowedRoles={["staff", "vendor", "admin"]} />,
      },
      {
        path: "lead/verifier/:id",
        element: <PrivateRoute element={<LeadView />} allowedRoles={["staff", "vendor"]} />,
      },
      {
        path: "clients",
        element: (
          <PrivateRoute element={<Clients />} allowedRoles={["admin", "vendor", 'client']} />
        ),
      },
      {
        path: "vendors",
        element: (
          <PrivateRoute element={<Vendors />} allowedRoles={["admin", "vendor"]} />
        ),
      },
      {
        path: "vendor/lead/create/api",
        element: (
          <PrivateRoute element={<ApiLeadsForm />} allowedRoles={["vendor"]} />
        ),
      },
      {
        path: "question",
        element: (
          <PrivateRoute element={<Question />} allowedRoles={["admin"]} />
        ),
      },
      {
        path: "staff",
        element: (
          <PrivateRoute element={<Staff />} allowedRoles={["admin"]} />
        ),
      },
      {
        path: "campaigns",
        element: (
          <PrivateRoute element={<Campaigns />} allowedRoles={["admin"]} />
        ),
      },
      {
        path: "request",
        element: (
          <PrivateRoute element={<Request />} allowedRoles={["admin", "staff"]} />
        ),
      },
      {
        path: "lead-history",
        element: (
          <PrivateRoute element={<LeadHistory />} allowedRoles={["admin"]} />
        ),
      },
      {
        path: "sub-admin",
        element: (
          <PrivateRoute element={<SubAdmin />} allowedRoles={["admin"]} />
        ),
      },
      {
        path: "status",
        element: (
          <PrivateRoute element={<StatusModule />} allowedRoles={["admin"]} />
        ),
      },
    ],
  },
  {
    path: "unauthorized",
    element: <Unauthorized />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default router;
