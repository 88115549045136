import AuthService from './authService';
import QuestionService from './questionService';
import LeadsService from './leads';
import CampaignsService from './campaignService';
import UserService from './userService';
import ClientService from './clientService';
import DashboardService from './dashboardService';
import ApiHistoryService from './apiHistory';
import statusService from './statusService';

const apiService = {
    auth: AuthService,
    question: QuestionService,
    leads: LeadsService,
    campaigns: CampaignsService,
    user: UserService,
    client: ClientService,
    dashboard: DashboardService,
    apiHistory: ApiHistoryService,
    status: statusService
};

export default apiService;
