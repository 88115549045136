export const userRoles = [
    { value: 'subAdmin', label: 'SubAdmin' },
    { value: 'vendor', label: 'Vendor' },
    { value: 'staff', label: 'Staff' },
]
export const roleList = [
    { value: '', label: 'All' },
    { value: 'vendor', label: 'Vendor' },
    { value: 'staff', label: 'Staff' },
];

export const options = [
    { value: '', label: 'All' },
    { value: 'submitted', label: 'Submitted' },
    { value: 'pending', label: 'Pending' },
    { value: 'approve', label: 'Approved' },
    { value: 'rejected', label: 'Rejected' },
];

export const roleOptions = [
    { title: "Verifier", value: "verifier" },
    { title: "Lead management", value: "lead_management" },
];

export const statusList = [
    { label: "Answering Machine", value: 'answering_machine' },
    { label: "Callback", value: 'callback' },
    { label: "Verified", value: 'verified' },
    { label: "Am", value: 'am' },
    { label: "Voicemail", value: 'voicemail' },
    { label: "Dead Air", value: 'dead_air' },
    { label: "caller hung up", value: 'caller_hung_up' },
    { label: "Callback Scheduled", value: 'callback_scheduled' },
    { label: "Number Out Of Service", value: 'number_out_of_service' },
    { label: "Wrong Number", value: 'wrong_number' },
    { label: "Already a Client", value: 'already_a_client' }, //
    { label: "Not Interested", value: 'not_interested' },
    { label: "Disqualified", value: 'disqualified' }, //
    { label: "No Answer", value: 'no_answer' },
    { label: "Ringing", value: 'ringing' },
    { label: "Retainer Sent", value: 'retainer_sent' },
    { label: "Does Not Qualify", value: 'does_not_qualify' },
    { label: "Busy", value: "busy" },
]

export const afterSubmitToAto = ["approve", "reject", "return", "replace", "billable"]