import React, { useCallback, useEffect, useState } from "react";
import apiService from "../../apiService";
import { useParams } from "react-router-dom";
import Skeleton from "../skeletonloader/Request";
import { toast } from "react-toastify";
import { afterSubmitToAto, statusList } from "../../utils/constant";
import { convertStatusToOption } from "../../utils/function";

const LeadView = () => {
  const { id: leadId } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("");
  const [apiStatuses, setApiStatuses] = useState([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await apiService.leads.getAssignedLead(leadId);
      if (result?.success) {
        setData(result.data);
        setSelectedStatus(result?.data?.status);
      }
    } catch (error) {
      console.error("Failed to fetch lead data:", error);
    } finally {
      setLoading(false);
    }
  }, [leadId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const fetchAllStatus = useCallback(async () => {
    const data = await apiService.status.getAllStatus();
    if (data.success) {
      setApiStatuses(convertStatusToOption(data?.data?.statuses));
    }
  }, []);

  useEffect(() => {
    fetchAllStatus();
  }, [fetchAllStatus]);

  const onStatusChange = async (status) => {
    try {
      const data = await apiService.leads.updateLead(leadId, { status });
      if (data.success) {
        toast.success(data?.message || "");
        fetchData();
      }
    } catch (error) {
      console.error("Failed to update lead status:", error);
    }
  };

  return (
    <div className="mt-4    overflow-hidden">
      {loading ? (
        <Skeleton count={4} />
      ) : (
        <>
          <div className="flex justify-between max-md:flex-col  mb-5  gap-5">
            <div className=" flex justify-center items-center flex-col flex-1 bg-[#F0EEF1] p-7 rounded-[10px] border border-[#E5E7EB]">
              <img
                src="/assets/svg/leads/staffuser.svg"
                alt=""
                className=" h-10 w-10"
              />
              <h1 className=" pt-3 text-lg">
                <span className=" block leading-[1] font-medium text-[#363D4E]">
                  Created By
                </span>{" "}
                <span className="  block text-center text-base text-[#868686]">
                  {data?.createdBy?.name}
                </span>
              </h1>
            </div>

            <div className="flex-2 flex justify-start items-center bg-[#F0EEF1] p-7 rounded-[10px] border border-[#E5E7EB]">
              {afterSubmitToAto.includes(data.status) ? (
                <b style={{ textTransform: "capitalize" }}>
                  {statusList.find((d) => d.value === data?.status)?.label}
                </b>
              ) : (
                <div className="flex gap-4">
                  {[
                    { label: "Answering Machine", value: "answering_machine" },
                    { label: "Callback", value: "callback" },
                    { label: "Verified", value: "verified" },
                    { label: "Am", value: "am" },
                    { label: "Voicemail", value: "voicemail" },
                    { label: "Busy", value: "busy" },
                    { label: "Caller Hung Up", value: "caller_hung_up" },
                    {
                      label: "Callback Scheduled",
                      value: "callback_scheduled",
                    },
                    {
                      label: "Number Out Of Service",
                      value: "number_out_of_service",
                    },
                    { label: "Wrong Number", value: "wrong_number" },
                    { label: "Already a Client", value: "already_a_client" }, //
                    { label: "Not Interested", value: "not_interested" },
                    { label: "Disqualified", value: "disqualified" }, //
                    { label: "No Answer", value: "no_answer" },
                    { label: "Ringing", value: "ringing" },
                    { label: "Retainer Sent", value: "retainer_sent" },
                    { label: "Does Not Qualify", value: "does_not_qualify" },
                  ].map((status) => (
                    <div
                      key={status.value}
                      className={`flex items-center gap-2 px-3 py-2 rounded-md font-medium cursor-pointer border ${
                        selectedStatus === status.value
                          ? "bg-black text-white"
                          : "bg-white text-black"
                      }`}
                      onClick={() => {
                        setSelectedStatus(status.value);
                        onStatusChange(status.value);
                      }}
                    >
                      <input
                        type="radio"
                        checked={selectedStatus === status.value}
                        onChange={() => {}}
                        className="cursor-pointer"
                      />
                      <span>{status.label}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <p className="text-start rounded-tl-[10px] max-sm:text-base custom-bg pl-4  mt-2 h-10 my-auto flex items-center text-lg font-semibold max-w-[210px] capitalize  text-white ">
            answer
          </p>

          <div className="rounded-r-[10px] rounded-b-[10px] p-5 bg-white grid grid-cols-2 gap-4 overflow-x-auto border border-[#7FB8EF]">
            {data?.responses?.map((resp, index) => (
              <div key={index} className="bg-[#F8F8F8] p-3 rounded-[5px]">
                <div>
                  <label className="text-base font-medium flex items-center gap-3">
                    <span className="text-black">{resp?.questionTitle}</span>
                  </label>
                  <input
                    type="text"
                    value={resp?.response || ""}
                    readOnly
                    className="mt-2 text-sm text-[#868686] border border-[#E5E7EB] bg-[#F0EEF1] p-2 rounded-[5px] w-full"
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default LeadView;
