import React, { useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import apiService from '../../apiService';
import { userRoles } from '../../utils/constant';

const statusValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  color: Yup.string().required('Color is required'),
  visibleTo: Yup.array().min(1, 'At least one role must be selected').required('Visible to is required'),
});

const AddStatusModal = ({ isOpen, onClose, fetchData, selectedStatus }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const payload = {
        ...values,
        visibleTo: values.visibleTo
      }
      if (selectedStatus) {
        await apiService.status.update(selectedStatus._id, payload);
        toast.success('Status updated successfully!');
      } else {
        await apiService.status.post(payload);
        toast.success('Status added successfully!');
      }
      onClose();
      fetchData();
    } catch (error) {} finally {
      setSubmitting(false);
    }
  }

  return (
    <>
      {isOpen && (
        <div className="modal-overlay p-2">
          <div className="modal pt-5 sm:px-8 sm:pb-10 px-4 pb-5 max-h-[90%] overflow-y-auto w-full max-w-[700px] mx-auto relative border border-border-color">
            <div className='flex justify-between items-center'>
              <h1 className='text-xl font-bold text-center'>
                {selectedStatus ? 'Edit Status' : 'Add Status'}
              </h1>
              <button onClick={onClose} className='rounded-full h-[30px] w-[30px] text-sm border-2 border-black font-semibold ml-auto'>
                <i className="fa-solid text-black text-lg fa-xmark"></i>
              </button>
            </div>

            <div className="mt-4"></div>

            <Formik
              initialValues={{
                name: selectedStatus ? selectedStatus.name : '',
                color: selectedStatus ? selectedStatus.color : '#000000',
                visibleTo: selectedStatus ? selectedStatus.visibleTo : [],
              }}
              validationSchema={statusValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values, isSubmitting, isValid }) => (
                <Form className="modal-content flex flex-col gap-3">
                  <div className="flex items-center">
                    <label className="font-semibold text-base leading-5 flex-[3] max-w-[172px]">
                      Title
                    </label>
                    <div className="flex-[8]">
                      <Field
                        name="name"
                        type="text"
                        placeholder="Status Name"
                        className='outline-none w-full border border-[#C4C4C4] bg-[#F9F9F9] p-2 rounded-md font-medium text-sm'
                      />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <label className="font-semibold text-base leading-5 flex-[3] max-w-[172px]">
                      Visible to
                    </label>

                    <div role="group" aria-labelledby="checkbox-group" className='flex mt-4 items gap-5'>
                      {userRoles.map((role) => (
                        <label key={role.value} className="flex items-center justify-center gap-2">
                          <Field
                            type="checkbox"
                            name="visibleTo"
                            value={role.value}
                            class="peer relative h-4 w-4 cursor-pointer rounded border border-slate-300 shadow hover:shadow-md transition-all checked:border-slate-800 checked:bg-slate-800"
                          />
                          {role.label}

                        </label>
                      ))}
                    </div>
                  </div>

                  <div className="flex items-center">
                    <label className="font-semibold text-base leading-5 flex-[3] max-w-[172px]">
                      Status color
                    </label>
                    <Field
                      name="color"
                      type="color"
                      className='h-11 w-11 border-none rounded-md mt-2'
                    />
                  </div>

                  <div className="mt-10 flex justify-end gap-2">
                    <button
                      type="submit"
                      disabled={isSubmitting || !isValid}
                      className={`disabled:bg-gray-500 py-3 px-8 rounded-[15px] bg-black text-white text-sm font-semibold ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                      {isSubmitting ? 'Loading...' : selectedStatus ? "Update" : "Submit"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default AddStatusModal;
