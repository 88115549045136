import React from 'react'
import StatusTable from '../../components/table/Status'

const StatusModule = () => {
    return (
        <>
            <StatusTable
                title={"Statuses"}
                showButton={true}
            />
        </>
    )
}

export default StatusModule