import React, { useCallback, useState } from "react";
import apiService from "../../apiService";
import { toast } from "react-toastify";
import useBodyOverflow from "../../hooks/useBodyOverflow";

const Dropdown = React.lazy(() => import("react-dropdown"));

const StatusChangeModal = ({ isOpen, onClose, fetchData, apiStatuses, setSelectedLeads, selectedLeads = [] }) => {
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');

  useBodyOverflow(isOpen);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedStatus === '' || selectedStatus === 'select') {
      toast.error("Please select a valid status.");
      return;
    }

    setLoading(true);
    try {
      const result = await apiService.leads.updateStatusBulk({ statusId: selectedStatus, leadIds: selectedLeads });
      if (result.success) {
        toast.success("Status updated successfully for selected leads.");
        fetchData();
        setSelectedLeads([])
        onClose();
        setSelectedStatus('')
      } else {
        toast.error("Failed to update status.");
      }
    } catch (error) {
      toast.error("Error updating status: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDropdownChange = (option) => {
    setSelectedStatus(option.value);
  };

  const getFilterStatus = useCallback(() => {
    return apiStatuses
  }, [apiStatuses]);

  return (
    isOpen && (
      <div className="modal-overlay">
        <div className="modal pt-5 sm:px-8 p-6 min-h-auto max-w-[30%] mx-auto w-full">
          <div className="flex justify-between w-full items-center">
            <h2 className="text-center font-bold text-xl leading-6">
              Status Change
            </h2>

            <button
              type="button"
              className="rounded-full h-[30px] w-[30px] text-sm border-2 border-black font-semibold"
              onClick={() => {
                onClose();
                setSelectedStatus('')
              }}
            >
              <i className="fa-solid text-black text-lg fa-xmark"></i>
            </button>
          </div>

          <form className="w-full mt-8 mx-auto" onSubmit={handleSubmit}>
            <Dropdown
              options={getFilterStatus()}
              onChange={handleDropdownChange}
              value={selectedStatus}
              placeholder="Select Status"
              className="font-medium shrink-0 min-w-dropdown"
            />
            <button
              type="submit"
              className="bg-black text-white px-2 py-[7px] flex items-center flex-none text-base cursor-pointer font-medium text-start border border-border-dark rounded-[5px] mt-4 float-right"
              disabled={loading}
            >
              {loading ? 'Updating...' : 'Submit'}
            </button>
          </form>
        </div>
      </div>
    )
  );
};

export default StatusChangeModal;
