import React, { useState, useRef } from "react";
// USERNAME= Depo-provera

const ProjectDocument2 = () => {
  const data = [
    {
      id: 1,
      Key_Name: "contact_number",
      Field_Name: "ContactNumber",
      FieldType: "String",
      SampleValue: "(555) 555-1234",
      Description: "The contact number of the user.",
      is_Required: "Yes",
    },
    {
      id: 2,
      Key_Name: "email",
      Field_Name: "Email",
      FieldType: "String",
      SampleValue: "john.doe@gmail.com",
      Description: "The email number of the user.",
      is_Required: "Yes",
    },
    {
      id: 3,
      Key_Name: "dob",
      Field_Name: "Date of Birth",
      FieldType: "Date",
      SampleValue: "05-02-2000",
      Description: "The user's date of birth. in mm-dd-yyyy format",
      is_Required: "Yes",
    },
    {
      id: 4,
      Key_Name: "address",
      Field_Name: "Address",
      FieldType: "String",
      SampleValue:
        "John Doe, 456 ElmStreet, Suite 3, Los Angeles, CA 90001, USA",
      Description: "The address of the user.",
      is_Required: "Yes",
    },
    {
      id: 5,
      Key_Name: "first_name",
      Field_Name: "First Name",
      FieldType: "String",
      SampleValue: "John",
      Description: "The user's first name.",
      is_Required: "Yes",
    },
    {
      id: 6,
      Key_Name: "last_name",
      Field_Name: "Last Name",
      FieldType: "String",
      SampleValue: "Doe",
      Description: "The user's last name.",
      is_Required: "Yes",
    },
    {
      id: 7,
      Key_Name: "ip_address",
      Field_Name: "IP Address",
      FieldType: "String",
      SampleValue: "192.168.123.132",
      Description: "The user's IP address at the time of access.",
      is_Required: "Yes",
    },
    {
      id: 8,
      Key_Name: "lp_url",
      Field_Name: "Landing page url",
      FieldType: "String",
      SampleValue: "http://leadbriefcase.com/",
      Description: "landing page url",
      is_Required: "Yes",
    },
    {
      id: 9,
      Key_Name: "represented_by_attorney",
      Field_Name: "Represented by Attorney",
      FieldType: "String",
      SampleValue: "No",
      Description:
        "Do you already have an attorney representing this case? Accepted - No",
      is_Required: "Yes",
    },
    {
      id: 10,
      Key_Name: "diagnosed",
      Field_Name: "What were you diagnosed with?",
      FieldType: "Text",
      SampleValue: "Text",
      Description:
        "Were you or a loved one Diagnosed with any of the following injuries after using the product? Accepted - Meningioma",
      is_Required: "Yes",
    },
    {
      id: 11,
      Key_Name: "zip_code",
      Field_Name: "Postal Code",
      FieldType: "Text",
      SampleValue: "78124",
      Description: "",
      is_Required: "Yes",
    },
    {
      id: 12,
      Key_Name: "product_name",
      Field_Name: "Product Name",
      FieldType: "Text",
      SampleValue: "List",
      Description:
        "What product/brand did you use? Accepted - Depo-Provera, Depo-SubQ Provera, Generic (Medroxyprogesterone acetate)",
      is_Required: "Yes",
    },
    {
      id: 13,
      Key_Name: "use_product",
      Field_Name: "Use product",
      FieldType: "Text",
      SampleValue: "List",
      Description:
        "Did you or a loved one use an injectable contraceptive for at least 1 year or longer? Accepted - Yes",
      is_Required: "Yes",
    },
    {
      id: 14,
      Key_Name: "trusted_form_certificate",
      Field_Name: "Trusted Form Certificate",
      FieldType: "Text",
      SampleValue: "String",
      Description: `http://leadbriefcase.com/`,
      is_Required: "Yes",
    },
    {
      id: 15,
      Key_Name: "what_product_did_you_use",
      Field_Name: "What product did you use?",
      FieldType: "Text",
      SampleValue: "Dove",
      Description: "Used product",
      is_Required: "Yes",
    },
    {
      id: 16,
      Key_Name: "did_you_use_for_more_than_one_year",
      Field_Name: "Did you use for more than one year",
      FieldType: "Text",
      SampleValue: "Yes",
      Description: "Yes/No",
      is_Required: "Yes",
    },
    {
      id: 17,
      Key_Name: "were_you_diagnosed_with_meningioma",
      Field_Name: "Were you diagnosed with meningioma",
      FieldType: "Text",
      SampleValue: "Yes",
      Description: "Yes/No",
      is_Required: "Yes",
    },
  ];

  return (
    <div className="container mx-auto w-full sm:px-10 px-2">
      <div className=" text-[#323d47] pt-10  inline-block  py-3 text-xl font-semibold ">
        Lead Briefcase - Vendor Api Documentation:-
      </div>
      <h5>
        <b>Name:</b> <span>Vendor api H0ld3nAPIDepo</span>
      </h5>
      <div className="py-8">
        <div className=" w-full">
          <div className="bg-white mb-8 mx-[10px]">
            <div>
              <h2 className="text-[2rem]  text-[#323d47] leading-[1.1] mt-5 mb-[10px]">
                {" "}
                <span className="text-[#50a1ff]">#</span> Direct Post
              </h2>
              <p className="mb-[10px] text-xl  text-primary leading-[1.4] font-thin pl-6">
                These instructions describe how to post leads to and understand
                what to expect in response.
              </p>
            </div>
            <div className="overflow-auto">
              <table className="w-full table-fixed border border-[#eaeff4]">
                <thead>
                  <tr className="border-b border-[#eaeff4] text-[#757575]">
                    <th className="p-[0.75rem] w-[100px] sm:w-[160px] text-left text-[0.9375rem] font-medium capitalize border-r border-[#eaeff4]">
                      Method:
                    </th>
                    <th className="p-[0.75rem] text-left text-[0.9375rem]">POST</th>
                  </tr>
                  <tr className="border-b border-[#eaeff4] text-[#757575]">
                    <th className="p-[0.75rem] text-[0.9375rem] text-left font-medium capitalize border-r border-[#eaeff4]">
                      URL:
                    </th>
                    <th className="p-[0.75rem] text-left text-[0.9375rem]">
                      https://leadbriefcase.com/api/vendor/lead-by-api
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b border-[#eaeff4] text-[#757575]">
                    <td className="p-[0.75rem] text-[0.9375rem] font-medium text-primary capitalize border-r border-[#eaeff4]">
                      Headers:
                    </td>
                    <td className="p-[0.75rem] bg-[#fdf6e3] text-[0.9375rem] text-[#757575]">
                      <div className="overflow-auto">
                        <pre className="whitespace-pre-wrap break-words">
                          {JSON.stringify(
                            {
                              "Content-Type": "application/json",
                              Authorization:
                                "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjYW1wSWQiOiI2NzNiZTc3YWY1ODkxZWQyZmU0YTFiYzAiLCJ1c2VySWQiOiI2NzYxYjI1ZmVmNzE1YWIxMGYyZDhhZjQiLCJpYXQiOjE3MzQ0NTU5MDN9.NcBgi7mo-RDtLfjVnvEjnAYBVmThKQee389dYf_6hkc",
                            },
                            null,
                            2
                          )}
                        </pre>
                      </div>
                    </td>
                  </tr>
                  <tr className="border-b border-[#eaeff4] text-[#757575]">
                    <td className="p-[0.75rem] align-top text-[0.9375rem] font-medium capitalize border-r border-[#eaeff4]">
                      Request Body Sample:
                    </td>
                    <td className="p-[0.75rem] bg-[#fdf6e3] text-[0.9375rem] text-[#657b83]">
                      <pre className="whitespace-pre-wrap break-words">
                        {JSON.stringify(
                          {
                            data: [
                              { key: "first_name", value: "John" },
                              { key: "last_name", value: "Doe" },
                              { key: "contact_number", value: "(555) 555-1234" },
                              { key: "email", value: "john.doe@gmail.com" },
                              { key: "zip_code", value: "78124" },
                              {
                                key: "address",
                                value:
                                  "John Doe, 456 Elm Street, Suite 3, Los Angeles, CA 90001, USA",
                              },
                              { key: "dob", value: "05-02-2000" },
                              { key: "product_name", value: "Depo-Provera" },
                              { key: "use_product", value: "Yes" },
                              { key: "diagnosed", value: "Meningioma" },
                              { key: "ip_address", value: "192.168.123.132" },
                              {
                                key: "lp_url",
                                value: "http://leadbriefcase.com/",
                              },
                              { key: "represented_by_attorney", value: "No" },
                              {
                                "key": "trusted_form_certificate",
                                "value": "http://leadbriefcase.com/"
                              },
                              { key: "what_product_did_you_use", value: "Dove" },
                              { key: "did_you_use_for_more_than_one_year", value: "No" },
                              { key: "were_you_diagnosed_with_meningioma", value: "Yes" },

                            ],
                          },
                          null,
                          2
                        )}
                      </pre>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* direct post data end */}

          {/* table data start */}
          <div className=" mt-14 mb-8">
            <div>
              <h2 className="text-[2rem]  text-[#323d47] leading-[1.1] mb-[10px]">
                <span className="text-[#50a1ff]">#</span> Fields
              </h2>
            </div>
            <div className="">
              <p className="mb-[10px] text-xl  text-primary leading-[1.4] font-thin pl-6">
                The following fields need to be included in the API body to
                create a new lead.
              </p>
            </div>
            <div className="overflow-x-auto mt-5 pl-6">
              <table className="min-w-full border border-[#EBEBEB]">
                <thead className="bg-[#F5F5F6]">
                  <tr className=" border-b border-[#DDDDD0] table-row">
                    <th className="p-2   text-left text-xs border-r border-[#DDDDD0]  font-bold text-[#676a6c] capitalize tracking-wider leading-[1.4]">
                      #
                    </th>
                    <th className="p-2   text-left text-xs border-r border-[#DDDDD0]  font-bold text-[#676a6c] capitalize tracking-wider leading-[1.4]">
                      Key Name
                    </th>
                    <th className="p-2   text-left text-xs border-r border-[#DDDDD0]  font-bold text-[#676a6c] capitalize tracking-wider leading-[1.4]">
                      Field Name
                    </th>
                    <th className="p-2   text-left text-xs border-r border-[#DDDDD0]  font-bold text-[#676a6c] capitalize tracking-wider leading-[1.4]">
                      Field Type
                    </th>
                    <th className="p-2   text-left text-xs border-r border-[#DDDDD0]  font-bold text-[#676a6c] capitalize tracking-wider leading-[1.4]">
                      Sample Value
                    </th>
                    <th className="p-2   text-left text-xs border-r border-[#DDDDD0]  font-bold text-[#676a6c] capitalize tracking-wider leading-[1.4]">
                      Description
                    </th>
                    <th className="p-2   text-left text-xs border-r border-[#DDDDD0]  font-bold text-[#676a6c] capitalize tracking-wider leading-[1.4]">
                      Is Required
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data.length > 0 &&
                    data.map((elements, ind) => (
                      <tr key={elements.id}>
                        <td className="p-2 w-[5%]  text-wrap  font-medium   text-[13px] text-[#676a6c] leading-[1.4] border border-[#e7e7e7]">
                          {elements.id}
                        </td>
                        <td className="p-2 w-[10%]  text-wrap font-medium text-[13px] text-[#676a6c] leading-[1.4] border border-[#e7e7e7]">
                          {elements.Key_Name}
                        </td>
                        <td className="p-2  text-wrap w-[20%]  font-medium text-[13px] text-[#676a6c] leading-[1.4] border border-[#e7e7e7]">
                          {elements.Field_Name}
                        </td>
                        <td className="p-2 w-[10%]  font-medium text-wrap   text-[13px] text-[#676a6c] leading-[1.4] border border-[#e7e7e7]">
                          {elements.FieldType}
                        </td>
                        <td className="p-2 w-[20%]  font-medium text-wrap   text-[13px] text-[#676a6c] leading-[1.4] border border-[#e7e7e7]">
                          {elements.SampleValue}
                        </td>{" "}
                        <td className="p-2 w-[30%] text-wrap font-medium   text-[13px] text-[#676a6c] leading-[1.4] border border-[#e7e7e7]">
                          {elements.Description}
                        </td>{" "}
                        <td className="p-2 w-[5%]  font-medium text-wrap   text-[13px] text-[#676a6c] leading-[1.4] border border-[#e7e7e7]">
                          {elements.is_Required}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* table data end */}

          {/* response data start */}

          <div className="bg-white  mt-14 pt-2 px-[10px] mb-8">
            <div>
              <h2 className="text-[2rem]  text-[#323d47] leading-[1.1] mt-5 mb-[10px]">
                <span className="text-[#50a1ff]">#</span> Responses
              </h2>
            </div>
            <div className="">
              <p className="mb-[10px] text-xl  text-primary leading-[1.4] font-thin pl-6">
                When a lead is created, a response is provided, typically in JSON format by default
              </p>
            </div>
            <div className="pl-6">
              <h2 className="text-[2rem]  text-[#323d47] leading-[1.1] mt-5 mb-[10px]">
                Accepted response
              </h2>
              <div className="relative bg-[#fdf6e3] p-[1em] w-full overflow-x-auto mt-5 border-l-[5px] border-[#DDDDD0] group">
                <pre>
                  {JSON.stringify(
                    {
                      success: true,
                      message: "Lead created successfully",
                      data: {
                        leadId: "lead-1",
                        userId: "user123",
                        campaignId: "campaign123",
                        responses: [
                          {
                            questionId: "q1",
                            response: "test@example.com",
                          },
                        ],
                        generated_by_api: true,
                        _id: "ID",
                        createdAt: "2024-12-10T12:34:56.789Z",
                        updatedAt: "2024-12-10T12:34:56.789Z",
                      },
                    },
                    null,
                    2
                  )}
                </pre>

                {/* Hover buttons */}
                <div className="absolute top-2 flex gap-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <button className="bg-blue-500 text-white p-1 rounded text-[10px] leading-[1] tracking-widest font-medium">
                    COPY
                  </button>
                  <div className=" text-primary text-xs p-1 rounded">JSON</div>
                </div>
              </div>
              <div className="  mt-14">
                <h2 className="text-[2rem]  text-[#323d47] leading-[1.1] mt-5 mb-[10px]">
                  Error response
                </h2>
                <div className="relative bg-[#fdf6e3] overflow-x-auto w-full p-[1em] mt-5 border-l-[5px] border-[#DDDDD0] group">
                  <pre>
                    {JSON.stringify(
                      {
                        status: 400,
                        success: false,
                        message: "Invalid email format",
                      },

                      null,
                      2
                    )}
                  </pre>

                  {/* Hover buttons */}
                  <div className="absolute top-2 flex gap-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <button className="bg-blue-500 text-white p-1 rounded text-[10px] leading-[1] tracking-widest font-medium">
                      COPY
                    </button>
                    <div className=" text-primary text-xs p-1 rounded">
                      JSON
                    </div>
                  </div>
                </div>
              </div>
              <div className="  mt-14">
                <h2 className="text-[2rem]  text-[#323d47] leading-[1.1] mt-5 mb-[10px]">
                  Duplicated response
                </h2>
                <div className="relative bg-[#fdf6e3] w-full overflow-x-auto p-[1em] mt-5 border-l-[5px] border-[#DDDDD0] group">
                  <pre>
                    {JSON.stringify(
                      {
                        status: 400,
                        success: false,
                        message:
                          "A lead with the same email or phone number already exists.",
                      },

                      null,
                      2
                    )}
                  </pre>

                  {/* Hover buttons */}
                  <div className="absolute top-2 flex gap-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <button className="bg-blue-500 text-white p-1 rounded text-[10px] leading-[1] tracking-widest font-medium">
                      COPY
                    </button>
                    <div className=" text-primary text-xs p-1 rounded">
                      JSON
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* response data end */}
        </div>
      </div>
    </div>
  );
};

export default ProjectDocument2;
